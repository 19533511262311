export default class TaxesApiService implements Taxes.Services.ITaxesApiService {
    private _http: angular.IHttpService;
    private _resourceUrl: string;

    constructor($http: angular.IHttpService) {
        this._http = $http;
        this._resourceUrl = "api/travel/taxes";
    }

    public getAll(filter: Taxes.Dto.Queries.IAllTaxesQuery): angular.IPromise<Taxes.Dto.ITaxesDto> {
        return this._http
            .get(this._resourceUrl, { params: filter })
            .then((result: any) => { return result.data; });
    }
    public create(tax: Taxes.Dto.ITaxDto): angular.IPromise<any> {

        let data = { tax: tax };
        return this._http
            .post(this._resourceUrl, data)
            .then(result => result.data);
    }
    public update(tax: Taxes.Dto.ITaxDto): angular.IPromise<any> {
        let data = { tax: tax };
        let requestUrl = `${this._resourceUrl}/${tax.id}`;
        return this._http
            .put(requestUrl, data);
    }
    public delete(tax: Taxes.Dto.ITaxDto): angular.IPromise<any> {
        let requestUrl = `${this._resourceUrl}/${tax.id}`;
        return this._http
            .delete(requestUrl);
    }
}
