export default class TaxUpdateController {
    // bindings
    public onUpdateComplete: () => any;
    public onLoadStarted: () => any;
    public onLoadCompleted: () => any;
    public onSelectCountryClicked: ()=>any;
    public onSelectStationClicked: ()=>any;
    public onSelectClassClicked: ()=>any;
    public onSelectProductClicked: ()=>any;
    public toolbar: any[];
    public tax: Taxes.Dto.ITaxDto;

    // view model
    public stores: Stores.Dto.IStore[];
    public currencies: Currencies.Dto.ICurrencyDto[];
    public taxForm: angular.IFormController;
    // holds datepikers visibility
    public datepickers: any;
    public datepickerOptions: any;

    // private
    private _storesApiService: Stores.Services.IStoresApiService;
    private _taxesApiService: Taxes.Services.ITaxesApiService;
    private _currenciesApiService: Currencies.Services.ICurrenciesApiService;

    constructor(
        storesApiService: Stores.Services.IStoresApiService,
        taxesApiService: Taxes.Services.ITaxesApiService,
        currenciesApiService: Currencies.Services.ICurrenciesApiService
    ) {
        this._storesApiService = storesApiService;
        this._taxesApiService = taxesApiService;
        this._currenciesApiService = currenciesApiService;

        if (this.tax.serializedExpressions) {
            this.tax.expressions = JSON.parse(this.tax.serializedExpressions);
        }

        this._initializeDatepickers();
        this._initializeToolbar();
        this._load();
    }

    public save() {
        if (this.taxForm.$invalid) {
            console.error("form is invalid");
            return;
        }

        this.tax.serializedExpressions = JSON.stringify(this.tax.expressions);
        this._taxesApiService
            .update(this.tax)
            .then((result) => {
                // TODO: There is no way to reload parent blade
                let handler = this.onUpdateComplete();
                if (typeof handler === "function") {
                    handler();
                }
            })
            .catch(error => console.error(error));
    }
    public showDatepicker($event: any, which: string) {
        $event.preventDefault();
        $event.stopPropagation();

        this.datepickers[which] = true;
    }
    public onSelectCountryClick(parentElement: any, parentBlade: any): void{
        let handler = this.onSelectCountryClicked();
        if(typeof(handler)==="function"){
            handler(parentElement, parentBlade);
        }
    }
    public onSelectStationClick(parentElement: any, parentBlade: any): void{
        let handler = this.onSelectStationClicked();
        if(typeof(handler)==="function"){
            handler(parentElement, parentBlade);
        }
    }

    public onSelectClassClick(parentElement: any, parentBlade: any): void{
        let handler = this.onSelectClassClicked();
        if(typeof(handler)==="function"){
            handler(parentElement, parentBlade);
        }
    }

    public onSelectProductClick(parentElement: any, parentBlade: any): void{
        let handler = this.onSelectProductClicked();
        if(typeof(handler)==="function"){
            handler(parentElement, parentBlade);
        }
    }

    private _load() {
        this._startLoad();
        this._storesApiService
            .getAll()
            .then((stores) => {
                this.stores = stores;
                return this._currenciesApiService.getAll();
            })
            .then((result) => {
                this.currencies = result;
                this._completeLoad();
            })
            .catch(error => console.error(error));
    }

    private _initializeDatepickers() {
        this.datepickers =
            {
                startDate: false,
                expiryDate: false
            };
        this.datepickerOptions =
            {
                "year-format": "'yyyy'",
            };
    }
    private _initializeToolbar() {
        this.toolbar =
            [
                {
                    name: "platform.commands.save",
                    icon: "fa fa-save",
                    executeMethod: () => this.save(),
                    canExecuteMethod: () => this.taxForm.$valid
                }
            ];
    }

    private _startLoad() {
        let handler = this.onLoadStarted();
        if (typeof handler === "function") {
            handler();
        }
    }
    private _completeLoad() {
        let handler = this.onLoadCompleted();
        if (typeof handler === "function") {
            handler();
        }
    }
}
