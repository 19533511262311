import * as itemTaxesBladeModule from "../item-taxes-blade/item-taxes-blade.module";
import { IItemBladeNavigationService } from "./item-services.interfaces";

export default class ItemBladeNavigationService implements IItemBladeNavigationService {
    private _coreNavigationService: any;
    constructor(coreNavigationService: any) {
        this._coreNavigationService = coreNavigationService;
    }

    public showAllTaxesBlade(parentBlade: any): void {
        let blade: Globals.Platform.IBladeConfiguration = {
            id: "tcs.taxes.item.item-taxes-blade.id",
            title: "Item Taxes",
            subtitle: "item taxes",
            controller: itemTaxesBladeModule.controllerName,
            template: itemTaxesBladeModule.templateUrl,
            vendor: parentBlade.vendor,
            toolbarCommands: [],
            data: null
        };
        this._coreNavigationService.showBlade(blade, parentBlade);
    }
}
