import Angular from "../../../globals/angular.global";

import * as platform from "../../../globals/platform/platform";
import * as itemServicesModule from "../../../item/services/item-services.module";
import * as orderServicesModule from "../../../order/order-services/order-services.module";
import * as travellerServicesModule from "../../../traveller/services/traveller-services.module";

import taxesMainBladeController from "./tax-blade.controller";

// constants
export const name = "tcs.taxes.tax-blade";
export const controllerName = "tcs.taxes.tax-blade.ctrl";
export const templateUrl = "tcs.taxes.tax-blade.html";

// module
Angular
    .module(name,
    [
        travellerServicesModule.name,
        orderServicesModule.name,
        itemServicesModule.name,
        platform.moduleName
    ])
    .controller(controllerName,
    [
        "$scope",
        travellerServicesModule.navigationServiceName,
        orderServicesModule.navigationServiceName,
        itemServicesModule.navigationServiceName,
        platform.bladesServiceName,
        taxesMainBladeController
    ])
    .run(["$templateCache", ($templateCache: angular.ITemplateCacheService) => {
        let bladeTemplate = require("./tax-blade.html");
        $templateCache.put(templateUrl, bladeTemplate);
    }]);
