import * as travellerTaxesBladeModule from "../traveller-taxes-blade/traveller-taxes-blade.module";

export default class TravellerBladeNavigationService implements Traveller.Services.ITravellerBladeNavigationService {
    private _coreNavigationService: any;
    constructor(coreNavigationService: any) {
        this._coreNavigationService = coreNavigationService;
    }

    public showAllTaxesBlade(parentBlade: any): void {
        let blade: Globals.Platform.IBladeConfiguration = {
            id: "tcs.taxes.traveller.travaller-taxes-blade.id",
            title: "Traveller Taxes",
            subtitle: "traveller taxes",
            controller: travellerTaxesBladeModule.controllerName,
            template: travellerTaxesBladeModule.templateUrl,
            toolbarCommands: [],
            vendor: parentBlade.vendor,
            data: null
        };
        this._coreNavigationService.showBlade(blade, parentBlade);
    }
}
