export default class OrderTaxesController {
    // bindings
    public listChangedEventHandlers: any[];
    public onLoadStarted: () => any;
    public onLoadCompleted: () => any;
    public onCreateClicked: () => any;
    public onUpdateClicked: () => any;
    public toolbar: any[];

    public parentBlade: any;
    // view model
    public taxes: Taxes.Dto.ITaxDto[];
    public total: number;
    public hasPager: boolean;
    public filter: Taxes.Dto.Queries.IAllTaxesQuery;
    public updateClicked: (tax: Taxes.Dto.ITaxDto) => void;
    public deleteClicked: (tax: Taxes.Dto.ITaxDto) => void;

    private _taxesApiService: Taxes.Services.ITaxesApiService;

    constructor
        (
            public $scope: any,
            taxesApiService: Taxes.Services.ITaxesApiService
        ) {
        this._taxesApiService = taxesApiService;

        this.updateClicked = (tax: Taxes.Dto.ITaxDto): void => {
            let handler = this.onUpdateClicked();
            if (typeof handler === "function") {
                handler(tax);
            }
        };
        this.deleteClicked = (tax: Taxes.Dto.ITaxDto): void => {
            this._taxesApiService
                .delete(tax)
                .then((result: any) => {
                    this._load();
                })
                .catch((error: any) => {
                    console.error(error);
                });
        };
        this.parentBlade = $scope.$parent.blade;
        this._initializeFilter();
        this._initializeToolbar();
        this._initializeEventHandlers();
        this._load();
    }

    public keywordChanged(event: KeyboardEvent): void {
        if (event.which !== 13 || !this.filter.keyword) {
            return;
        }
    }
    public clearKeyword(): void {
        this.filter.keyword = "";
    }

    private _load(): void {
        this._startLoad();
        this._taxesApiService
            .getAll(this.filter)
            .then((result: Taxes.Dto.ITaxesDto) => {
                this.taxes = result.collection;
                this.total = this.taxes.length;
                this.hasPager = this.filter.itemsPerPage < this.total;

                this._completeLoad();

                return this.taxes;
            })
            .catch((error: any) => console.error(error));
    }
    private _initializeFilter(): void {
        this.filter =
            {
                keyword: "",
                itemsPerPage: 20,
                currentPage: 1,
                level: TaxLevel.Order,
                storeId: this.parentBlade.vendor.storeId
            };
    }

    private _initializeToolbar(): void {
        this.toolbar =
            [
                {
                    name: "platform.commands.refresh",
                    icon: "fa fa-refresh",
                    executeMethod: () => this._load(),
                    canExecuteMethod: () => true
                },
                {
                    name: "platform.commands.add",
                    icon: "fa fa-plus",
                    executeMethod: () => this._createClicked(),
                    canExecuteMethod: () => true,
                    // permission: 'marketing:create'
                }
            ];
    }

    private _initializeEventHandlers(): void {
        let dataChangedHandler = () => { this._load(); };
        this.listChangedEventHandlers.push(dataChangedHandler);
    }

    private _startLoad() {
        let handler = this.onLoadStarted();
        if (typeof handler === "function") {
            handler();
        }
    }
    private _completeLoad() {
        let handler = this.onLoadCompleted();
        if (typeof handler === "function") {
            handler();
        }
    }
    private _createClicked() {
        let handler = this.onCreateClicked();
        if (typeof handler === "function") {
            handler();
        }
    }
}
