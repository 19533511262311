// dependencies
import Angular from "../../globals/angular.global";

import * as platform from "../../globals/platform/platform";
import * as orderTaxesBladeModule from "../order-taxes-blade/order-taxes-blade.module";

import orderBladeNavigationService from "./order.blade.navigation";

// constants
export const name = "tcs.taxes.order.services";
export const navigationServiceName = "tcs.taxes.order.services.blade-navigation";

// module
Angular
    .module(name,
    [
        platform.moduleName,
        orderTaxesBladeModule.name
    ])
    .service(navigationServiceName,
    [
        platform.bladesServiceName,
        orderBladeNavigationService
    ]);
