export default class TaxesMainBladeController {
    private _travelerBladeNavigationService: any;
    private _orderBladeNavigationService: any;
    private _itemBladeNavigationService: any;
    private _taxesBladeNavigationService: any;
    private _coreNavigationService: any;
    private _currentBlade: any;
    private _parentBlade: any;

    constructor(
        public $scope: any,
        travelerBladeNavigationService: any,
        orderBladeNavigationService: any,
        itemBladeNavigationService: any,
        coreNavigationService: any,
        taxesBladeNavigationService: any
    ) {
        this._travelerBladeNavigationService = travelerBladeNavigationService;
        this._orderBladeNavigationService = orderBladeNavigationService;
        this._itemBladeNavigationService = itemBladeNavigationService;
        this._coreNavigationService = coreNavigationService;
        this._taxesBladeNavigationService = taxesBladeNavigationService;

        this._parentBlade = this.$scope.$parent.blade;
        this._currentBlade = this.$scope.blade;

        this.$scope.links = [];
        $scope.clickItem = (item: any) => {
            if (item.action) {
                item.action();
            }
            for (let i = 0; i < $scope.links.length; i++) {
                this.$scope.links[i].isSelected = false;
            }
            item.isSelected = true;
        };

        this._load();
    }

    private _load(): void {
        let itemSurcharges =
            {
                name: "Surcharges",
                icon: "fa fa-money",
                isSelected: false,
                action: () => {
                    let blade: Globals.Platform.IBladeConfiguration = {
                        id: "surchargesMainBlade",
                        title: 'Surcharges',
                        subtitle: 'Surcharges',
                        controller: 'tcs.surcharges.main-blade.ctrl',
                        template: 'Modules/$(TCS.Surcharges)/frontend/main-blade/main-blade.html',
                        vendor: this._currentBlade.vendor,
                        toolbarCommands: []
                    };
                    this._coreNavigationService.showBlade(blade, this._currentBlade);
                }
            };

        let vendorFees =
            {
                name: "Vendor Fees",
                icon: "fa fa-image",
                isSelected: false,
                action: () => {
                    let blade: Globals.Platform.IBladeConfiguration = {
                        id: "vendorFeesBlade",
                        title: 'Vendor Fees',
                        subtitle: 'Vendor Fees',
                        controller: 'tcs.pricingCostModule.mock',
                        template: 'Modules/$(TCS.PricingCost)/Scripts/blades/mock/view.tpl.html',
                        vendor: "",
                        toolbarCommands: []
                    };
                    this._coreNavigationService.showBlade(blade, this._currentBlade);
                }
            };
        let agencyFees =
            {
                name: "Agency Fees",
                icon: "fa fa-image",
                isSelected: false,
                action: () => {
                    let blade: Globals.Platform.IBladeConfiguration = {
                        id: "agencyFeesBlade",
                        title: 'Agency Fees',
                        subtitle: 'Agency Fees',
                        controller: 'tcs.pricingCostModule.mock',
                        template: 'Modules/$(TCS.PricingCost)/Scripts/blades/mock/view.tpl.html',
                        vendor: "",
                        toolbarCommands: []
                    };
                    this._coreNavigationService.showBlade(blade, this._currentBlade);
                }
            };

        let taxes =
            {
                name: "Taxes",
                icon: "fa fa-money",
                isSelected: false,
                action: () => {
                    this._taxesBladeNavigationService
                        .showTaxBlade(this._currentBlade);
                }
            };

        this.$scope.links.push(itemSurcharges, vendorFees, agencyFees, taxes);

        this._currentBlade.isLoading = false;
    }
}
