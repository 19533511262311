// dependencies
import Angular from "../../globals/angular.global";

import * as platform from "../../globals/platform/platform";

import TaxesNavigationService from "./taxes.navigation.service";
// constants
export const name = "tcs.taxes.navigation";
export const navigationServiceName = "tcs.taxes.navigation.service";

// module
Angular.module(name,
    [
        platform.moduleName
    ])
    .service(navigationServiceName,
    [
        platform.bladesServiceName,
        TaxesNavigationService
    ]);
