// dependencies
import Angular from "../../../globals/angular.global";

import * as currenciesApiModule from "../../../currencies/services/currencies.services.module";
import * as storesServicesModule from "../../../stores/services/stores.services.module";
import * as taxesServicesModule from "../../services/taxes.services.module";

import TaxUpdateController from "./tax-update.controller";

// constants
export const name = "tcs.taxes.tax-update";
const controllerName = "tcs.taxes.tax-update.ctrl";
const selectorName = "tcsTaxUpdate";

// module
Angular
    .module(name,
    [
        storesServicesModule.name,
        taxesServicesModule.name,
        currenciesApiModule.name
    ])
    .controller(controllerName,
    [
        storesServicesModule.apiServiceName,
        taxesServicesModule.apiServiceName,
        currenciesApiModule.apiServiceName,
        TaxUpdateController
    ])
    .directive(selectorName, () =>
    {
        let directive: angular.IDirective =
            {
                restrict: "E",
                template: require("./tax-update.html"),
                scope:
                {
                    onUpdateComplete: "&",
                    onLoadStarted: "&",
                    onLoadCompleted: "&",
                    onSelectCountryClicked: "&",
                    onSelectClassClicked: "&",
                    onSelectProductClicked: "&",
                    onSelectStationClicked: "&",
                    toolbar: "=",
                    tax: "="
                },
                controller: controllerName,
                controllerAs: "vm",
                bindToController: true
            };
        return directive;
    });
