// dependencies
import Angular from "../../../globals/angular.global";

import * as currenciesServicesModule from "../../../currencies/services/currencies.services.module";
import * as storesServicesModule from "../../../stores/services/stores.services.module";
import * as expressionsServicesModule from "../../expressions/services/expressions.services.module";
import * as taxesServicesModule from "../../services/taxes.services.module";

import TaxCreateController from "./tax-create.controller";

// constants
export const name = "tcs.taxes.tax-create";
const controllerName = "tcs.taxes.tax-create.ctrl";
const selectorName = "tcsTaxCreate";

// module
Angular
    .module(name,
    [
        storesServicesModule.name,
        taxesServicesModule.name,
        expressionsServicesModule.name,
        expressionsServicesModule.name,
        currenciesServicesModule.name
    ])
    .controller(controllerName,
    [
        storesServicesModule.apiServiceName,
        taxesServicesModule.apiServiceName,
        expressionsServicesModule.apiServiceName,
        expressionsServicesModule.uiServiceName,
        currenciesServicesModule.apiServiceName,
        TaxCreateController
    ])
    .directive(selectorName, () =>
    {
        let directive: angular.IDirective =
            {
                restrict: "E",
                template: require("./tax-create.html"),
                scope:
                {
                    onCreateComplete: "&",
                    onLoadStarted: "&",
                    onLoadCompleted: "&",
                    onSelectCountryClicked: "&",
                    onSelectClassClicked: "&",
                    onSelectStationClicked: "&",
                    onSelectProductClicked: "&",
                    toolbar: "=",
                    taxLevel: "="
                },
                controller: controllerName,
                controllerAs: "vm",
                bindToController: true
            };
        return directive;
    });
