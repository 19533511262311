// dependencies
import Angular from "../../globals/angular.global";

import * as createTaxBladeModule from "../../taxes/blades/tax-create-blade/tax-create-blade.module";
import * as updateTaxBladeModule from "../../taxes/blades/tax-update-blade/tax-update-blade.module";
import * as taxNavigationModule from "../../taxes/navigation/taxes.navigation.module";
import * as itemTaxesModule from "../item-taxes/item-taxes.module";

import ItemTaxesBladeController from "./item-taxes-blade.controller";

// constants
export const name = "tcs.taxes.item.item-taxes-blade";
export const controllerName = "tcs.taxes.item.item-taxes-blade.ctrl";
export const templateUrl = "tcs.taxes.item.item-taxes-blade.template.html";

// module
Angular
    .module(name,
    [
        itemTaxesModule.name,
        createTaxBladeModule.name,
        updateTaxBladeModule.name,
        taxNavigationModule.name
    ])
    .controller(controllerName,
    [
        "$scope",
        taxNavigationModule.navigationServiceName,
        ItemTaxesBladeController
    ])
    .run(["$templateCache", ($templateCache: angular.ITemplateCacheService) => {
        let bladeTemplate = require("./item-taxes-blade.html");
        $templateCache.put(templateUrl, bladeTemplate);
    }]);
