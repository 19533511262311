// dependencies
import Angular from "../../../globals/angular.global";

import core from "../../../globals/core/core";
import * as platform from "../../../globals/platform/platform";
import * as templateNames from "../templates/index";

import ExpressionsApiService from "./expressions.api.service";

import ExpressionUiSrevice from "./expressions.ui.service";

// constants
export const name = "tcs.taxes.expressions.services";
export const apiServiceName = "tcs.taxes.expressions.services.api";
export const uiServiceName = "tcs.taxes.expressions.services.ui";

// module
Angular.module(name,
    [
        core.moduleName
    ])
    .service(apiServiceName,
    [
        "$http",
        core.dynamicExpressionServiceName,
        ExpressionsApiService
    ])
    .service(uiServiceName,
    [
        platform.authServiceName,
        platform.bladesServiceName,
        ExpressionUiSrevice
    ])
    .run(["$templateCache", ($templateCache: angular.ITemplateCacheService) => {
        let ageConditionTemplate = require("../templates/age-condition.html");
        $templateCache.put(templateNames.ageConditionTemplateUrl, ageConditionTemplate);

        let ageConditionIsChildTemplate = require("../templates/age-condition-is-child.html");
        $templateCache.put(templateNames.ageConditionIsChildTemplateUrl, ageConditionIsChildTemplate);

        let ageConditionIsInfantTemplate = require("../templates/age-condition-is-infant.html");
        $templateCache.put(templateNames.ageConditionIsInfantTemplateUrl, ageConditionIsInfantTemplate);

        let ageConditionIsAdultTemplate = require("../templates/age-condition-is-adult.html");
        $templateCache.put(templateNames.ageConditionIsAdultTemplateUrl, ageConditionIsAdultTemplate);

        let countryConditionTemplate = require("../templates/country-condition.html");
        $templateCache.put(templateNames.countryConditionTemplateUrl,countryConditionTemplate);

        let countryConditionIsDepartsTemplate = require("../templates/country-condition-is-departs.html");
        $templateCache.put(templateNames.countryConditionIsDepartsTemplateUrl,countryConditionIsDepartsTemplate);

        let countryConditionIsArrivesTemplate = require("../templates/country-condition-is-arrives.html");
        $templateCache.put(templateNames.countryConditionIsArrivesTemplateUrl,countryConditionIsArrivesTemplate);

        let classConditionTemplate = require("../templates/class-condition.html");
        $templateCache.put(templateNames.classConditionTemplateUrl,classConditionTemplate);

        let classItemTemplate = require("../templates/class-item-condition.html");
        $templateCache.put(templateNames.classItemTemplateUrl,classItemTemplate);

        let stationConditionTemplate = require("../templates/station-condition.html");
        $templateCache.put(templateNames.stationConditionTemplateUrl,stationConditionTemplate);

        let stationConditionIsDepartsTemplate = require("../templates/station-condition-is-departs.html");
        $templateCache.put(templateNames.stationConditionIsDepartsTemplateUrl,stationConditionIsDepartsTemplate);

        let stationConditionIsArrivesTemplate = require("../templates/station-condition-is-arrives.html");
        $templateCache.put(templateNames.stationConditionIsArrivesTemplateUrl,stationConditionIsArrivesTemplate);

        let productConditionTemplate = require("../templates/product-condition.html");
        $templateCache.put(templateNames.productConditionTemplateUrl,productConditionTemplate);

        let productItemTemplate = require("../templates/product-item-condition.html");
        $templateCache.put(templateNames.productItemTemplateUrl,productItemTemplate);
    }]);
