// dependencies
import Angular from "../../globals/angular.global";

import TaxesApiService from "./taxes.api.service";

// constants
export const name = "tcs.taxes.tax-services";
export const apiServiceName = "tcs.taxes.tax-services.api-service";

// module
Angular
    .module(name,
    [
    ])
    .service(apiServiceName,
    [
        "$http",
        TaxesApiService
    ]);
