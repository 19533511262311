// dependencies
import Angular from "../../../globals/angular.global";

import * as platform from "../../../globals/platform/platform";

import * as templateNames from "./templates/index";

import TaxesListController from "./taxes-list.controller";

// constants
export const name = "tcs.taxes.taxes-list";
const controllerName = "tcs.taxes.taxes-list.ctrl";
const selectorName = "tcsTaxesList";

// module defined at platform scope
const gridCotrolModuleName = "ui.grid";

// module
Angular
    .module(name,
    [
        gridCotrolModuleName,
        platform.moduleName
    ])
    .controller(controllerName,
    [
        TaxesListController
    ])
    .directive(selectorName, () => {
        let directive: angular.IDirective =
            {
                restrict: "E",
                template: require("./taxes-list.html"),
                scope:
                {
                    taxes: "=",
                    onTaxClicked: "&",
                    onDelete: "&"
                },
                controller: controllerName,
                controllerAs: "vm",
                bindToController: true
            };
        return directive;
    })
    .run(["$templateCache", ($templateCache: angular.ITemplateCacheService) => {
        let actionsCellTemplate = require("./templates/actions-cell.html");
        $templateCache.put(templateNames.actionsCellTemplateName, actionsCellTemplate);

        let rowTemplate = require("./templates/row.html");
        $templateCache.put(templateNames.rowTemplateName, rowTemplate);
    }]);
