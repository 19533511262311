export default class TaxCreateController {
    // bindings
    public onCreateComplete: () => any;
    public onLoadStarted: () => any;
    public onLoadCompleted: () => any;
    public onSelectCountryClicked: ()=>any;
    public onSelectStationClicked: ()=>any;
    public onSelectClassClicked: ()=>any;
    public onSelectProductClicked: ()=>any;
    public toolbar: any[];
    public taxLevel: TaxLevel;

    // view model
    public tax: Taxes.Dto.ITaxDto;
    public stores: Stores.Dto.IStore[];
    public currencies: Currencies.Dto.ICurrencyDto[];
    public taxForm: angular.IFormController;
    // holds datepikers visibility
    public datepickers: any;
    public datepickerOptions: any;

    // private
    private _storesApiService: Stores.Services.IStoresApiService;
    private _taxesApiService: Taxes.Services.ITaxesApiService;
    private _expressionsApiService: Taxes.Expressions.Services.IExpressionsApiService;
    private _expressionsUiService: Taxes.Expressions.Services.IExpressionsUiService;
    private _currenciesApiService: Currencies.Services.ICurrenciesApiService;

    constructor
        (
        storesApiService: Stores.Services.IStoresApiService,
        taxesApiService: Taxes.Services.ITaxesApiService,
        expressionsApiService: Taxes.Expressions.Services.IExpressionsApiService,
        expressionsUiService: Taxes.Expressions.Services.IExpressionsUiService,
        currenciesApiService: Currencies.Services.ICurrenciesApiService
        ) {
        this._storesApiService = storesApiService;
        this._taxesApiService = taxesApiService;
        this._expressionsApiService = expressionsApiService;
        this._expressionsUiService = expressionsUiService;
        this._currenciesApiService = currenciesApiService;

        this._initializeDatepickers();
        this._initializeTax();
        this._initializeToolbar();
        this._load();
    }

    public save() {
        if (this.taxForm.$invalid) {
            console.error("form is invalid");
            return;
        }
        this.tax.serializedExpressions = JSON.stringify(this.tax.expressions);
        this._taxesApiService
            .create(this.tax)
            .then((result: any) => {
                let handler = this.onCreateComplete();
                if (typeof handler === "function") {
                    handler();
                }
            })
            .catch((error: any) => { console.error(error); });
    }
    public showDatepicker($event: any, which: string) {
        $event.preventDefault();
        $event.stopPropagation();

        this.datepickers[which] = true;
    }
    public onSelectCountryClick(parentElement: any, parentBlade: any): void{
        let handler = this.onSelectCountryClicked();
        if(typeof(handler)==="function"){
            handler(parentElement, parentBlade);
        }
    }
    public onSelectStationClick(parentElement: any, parentBlade: any): void{
        let handler = this.onSelectStationClicked();
        if(typeof(handler)==="function"){
            handler(parentElement, parentBlade);
        }
    }

    public onSelectClassClick(parentElement: any, parentBlade: any): void{
        let handler = this.onSelectClassClicked();
        if(typeof(handler)==="function"){
            handler(parentElement, parentBlade);
        }
    }
    public onSelectProductClick(parentElement: any, parentBlade: any): void{
        let handler = this.onSelectProductClicked();
        if(typeof(handler)==="function"){
            handler(parentElement, parentBlade);
        }
    }
    private _load() {
        this._startLoad();
        this._storesApiService
            .getAll()
            .then(stores => {
                this.stores = stores;
                return this._expressionsApiService.getDefaultTree(this.tax.level || TaxLevel.Default);
            })
            .then(expressions => {
                this.tax.expressions = expressions;
                return this._currenciesApiService.getAll();
            })
            .then((currencies: Currencies.Dto.ICurrencyDto[]) => {
                this.currencies = currencies;
                this._completeLoad();
            })
            .catch(error => console.error(error));
    }

    private _initializeDatepickers() {
        this.datepickers =
            {
                startDate: false,
                expiryDate: false
            };
        this.datepickerOptions =
            {
                "year-format": "'yyyy'",
            };
    }
    private _initializeTax() {
        this.tax = <Taxes.Dto.ITaxDto>{
            level: this.taxLevel || TaxLevel.Default,
            refundPercentage: 100
        };
    }
    private _initializeToolbar() {
        this.toolbar =
            [
                {
                    name: "platform.commands.save",
                    icon: "fa fa-save",
                    executeMethod: () => this.save(),
                    canExecuteMethod: () => this.taxForm.$valid
                }
            ];
    }
    private _startLoad() {
        let handler = this.onLoadStarted();
        if (typeof handler === "function") {
            handler();
        }
    }
    private _completeLoad() {
        let handler = this.onLoadCompleted();
        if (typeof handler === "function") {
            handler();
        }
    }
}
