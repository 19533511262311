import * as TaxCreateBladeModule from "../blades/tax-create-blade/tax-create-blade.module";
import * as TaxBladeModule from "../blades/tax-main-blade/tax-blade.module";
import * as TaxUpdateBladeModule from "../blades/tax-update-blade/tax-update-blade.module";

export default class TaxesBladeNavigationService implements Taxes.Navigation.ITaxesBladeNavigationService {
    private _coreNavigationService: any;
    constructor(coreNavigationService: any) {
        this._coreNavigationService = coreNavigationService;
    }

    public showCreateBlade(parentBlade: any, taxLevel: TaxLevel): void {
        let blade: Globals.Platform.IBladeConfiguration = {
            id: "tcs.taxes.tax-create-blade.id",
            title: "Create Tax",
            subtitle: "tax creation form",
            controller: TaxCreateBladeModule.controllerName,
            template: TaxCreateBladeModule.templateUrl,
            toolbarCommands: [],
            vendor: parentBlade.vendor,
            data:
            {
                taxLevel: taxLevel
            }
        };
        this._coreNavigationService.showBlade(blade, parentBlade);
    }

    public showUpdateBlade(parentBlade: any, tax: Taxes.Dto.ITaxDto): void {
        let blade: Globals.Platform.IBladeConfiguration = {
            id: "tcs.taxes.tax-update-blade.id",
            title: "Update Tax",
            subtitle: "tax update form",
            controller: TaxUpdateBladeModule.controllerName,
            template: TaxUpdateBladeModule.templateUrl,
            toolbarCommands: [],
            vendor: parentBlade.vendor,
            data:
            {
                tax: tax
            }
        };
        this._coreNavigationService.showBlade(blade, parentBlade);
    }

    public showTaxBlade(parentBlade: any): void {
        let blade: Globals.Platform.IBladeConfiguration = {
            id: "tcs.taxes.tax-blade.id",
            title: "Taxes",
            subtitle: "taxes",
            controller: TaxBladeModule.controllerName,
            template: TaxBladeModule.templateUrl,
            vendor: parentBlade.vendor,
            toolbarCommands: []
        };
        this._coreNavigationService.showBlade(blade, parentBlade);
    }
}
