import * as orderTaxesBladeModule from "../order-taxes-blade/order-taxes-blade.module";

export default class OrderBladeNavigationService implements Order.Services.IOrderBladeNavigationService {
    private _coreNavigationService: any;
    constructor(coreNavigationService: any) {
        this._coreNavigationService = coreNavigationService;
    }

    public showAllTaxesBlade(parentBlade: any): void {
        let blade: Globals.Platform.IBladeConfiguration = {
            id: "tcs.taxes.order.order-taxes-blade.id",
            title: "Order Taxes",
            subtitle: "order taxes",
            controller: orderTaxesBladeModule.controllerName,
            template: orderTaxesBladeModule.templateUrl,
            toolbarCommands: [],
            vendor: parentBlade.vendor,
            data: null
        };
        this._coreNavigationService.showBlade(blade, parentBlade);
    }
}
